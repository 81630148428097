import { createPublicClient,createWalletClient, http,custom } from 'viem'
import { mainnet } from 'viem/chains'

// const appChain = ['production'].includes(process.env.REACT_APP_ENV) ? mainnet : sepolia

export const appChain = mainnet

export const appWallectClient =  () => {
  return  createWalletClient({
    chain: mainnet,
    transport: custom(window.ethereum!)
  })
}

export const appPublicClient =  () => {
  return  createPublicClient({
    chain: appChain,
    transport: http(),
  })
}


