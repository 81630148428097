import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import './index.css';
import './util/lang/config';
import store from './redux/store'
import { Buffer } from 'buffer';
import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import {
  mainnet
} from 'wagmi/chains';
import { WagmiProvider, http } from 'wagmi'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'

import App from './App';
import { projectId } from './util/const/const';
import { CountlyService } from './util/helper/countlyUtil';

//Exposing Countly to the DOM as a global variable
//Usecase - Heatmaps

CountlyService.getInstance()

// const appChain  = (process.env.REACT_APP_ENV === 'production') ? mainnet : sepolia

const appChain = mainnet

const config = getDefaultConfig({
  appName: 'TG.Bet',
  projectId: projectId,
  chains: [mainnet],
  transports: {
    [mainnet.id]: http(),
  },
})

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.Buffer = window.Buffer || Buffer;

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <App />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
